<template>
    <contentCardIndex >
        <template slot="cardContent">
                <v-row align="center">
                    <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                         <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}" 
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                            data-cy="search-input"
                        ></alternativeTextField2>
                       
                        
                    </v-col>
                    <v-col cols="12" md="4" lg="4" class="mx-0 px-0 mx-md-3">
                        <alternativeAutocompleteOne
                            :items="variants"
                            :valueInput="variantSelected"
                            :loading="loadingVariants"
                           
                            @valueChange="(v) => {variantSelected = v}"
                            @onChange="(v) => {index()}"
                            data-cy="variantSelected-input"
                            :label="'Materias primas'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
                        
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col   cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                        <v-row justify="end">
                           
                            <primaryButton data-cy="export-btn" :props="exportBtnData"></primaryButton>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mt-md-0">
                    <v-col class="mx-0 px-0">
						<v-card class="index-card mx-0 px-0" flat color="transparent" >
                           
                            <v-card-text class="px-0">
                                <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler">
                        
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>

                    
                </v-row>
                
        </template>

        
    
    </contentCardIndex>

        
</template>
<script>
import Utils from '../../../helpers/Utils'
export default {
    data(){
		return {
            
            variantSelected: '',
            variants:[],
            loadingVariants:false,
			search: '',
            selectedID: '',
			
			tableProps: {
				headers: [
                    {
						text: 'Orden de compra',
						align: 'left break-words',
						value: 'purchase_order_folio',
						class: 'table-b-border black--text'
					},
                    {
						text: 'OT',
						align: 'left break-words',
						value: 'sale_order_folio',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Fecha',
						align: 'left break-words',
						value: 'date',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Precio',
						align: 'left break-words',
						value: 'price',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Proveedor',
						align: 'left break-words',
						value: 'supplier',
						class: 'table-b-border black--text'
					},
                    
                    
				],
				items: [],
				search: '',
				loading: false
			},
			
			
            exportBtnData: {
                text: "Exportar",
                icon: "",
				to: "",
				block: false,
                click: ()=>{this.export()}
            },
            

			
			
			
		}
	},

	mounted(){
		// this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.getVariants();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.exportBtnData.block = true;
                
            }
            else{
                this.exportBtnData.block = false;
                
            }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
        getVariants()
        {
            this.loadingVariants = true;
            this.$api.commodity.getSelectAll()
                .then((resp) => {
                    this.variants = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingVariants = false;
                })
            ;
        },
		index() {
            if(this.variantSelected == undefined || this.variantSelected == "")
                return;

			this.tableProps.loading = true;
            this.tableProps.items = [];
			// call api
			this.$api.report.purchaseOrdersByVariant(this.variantSelected)
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.reverse().map((x) => {
                    
                        x.price = Utils.currencyFormat(x.price, "");
                        x.date = Utils.globalDateFormat(x.date);

						return x;
					});

                  
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},



		export()
        {
            if(this.variantSelected == undefined || this.variantSelected == "")
                return;
            
            // const workData = this.works.find((item) => item.value == this.variantSelected)
            this.exportBtnData.loading = true;
            this.$api.report.exportPurchaseOrdersByVariant(this.variantSelected)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `Material.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                })
            ;
        },

    }
}
</script>